import { default as aboutYl3w7gunepMeta } from "/_app/pages/about.vue?macro=true";
import { default as contact_45ust1B7Gd8oQXMeta } from "/_app/pages/contact-us.vue?macro=true";
import { default as drinksOp0yyLopj0Meta } from "/_app/pages/drinks.vue?macro=true";
import { default as foodVO6JJm1Z0EMeta } from "/_app/pages/food.vue?macro=true";
import { default as galleryGippOHOjWmMeta } from "/_app/pages/gallery.vue?macro=true";
import { default as index7414TfuuzVMeta } from "/_app/pages/index.vue?macro=true";
import { default as our_45coffeegeOWnf69nAMeta } from "/_app/pages/our-coffee.vue?macro=true";
export default [
  {
    name: aboutYl3w7gunepMeta?.name ?? "about",
    path: aboutYl3w7gunepMeta?.path ?? "/about",
    meta: aboutYl3w7gunepMeta || {},
    alias: aboutYl3w7gunepMeta?.alias || [],
    redirect: aboutYl3w7gunepMeta?.redirect || undefined,
    component: () => import("/_app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contact_45ust1B7Gd8oQXMeta?.name ?? "contact-us",
    path: contact_45ust1B7Gd8oQXMeta?.path ?? "/contact-us",
    meta: contact_45ust1B7Gd8oQXMeta || {},
    alias: contact_45ust1B7Gd8oQXMeta?.alias || [],
    redirect: contact_45ust1B7Gd8oQXMeta?.redirect || undefined,
    component: () => import("/_app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: drinksOp0yyLopj0Meta?.name ?? "drinks",
    path: drinksOp0yyLopj0Meta?.path ?? "/drinks",
    meta: drinksOp0yyLopj0Meta || {},
    alias: drinksOp0yyLopj0Meta?.alias || [],
    redirect: drinksOp0yyLopj0Meta?.redirect || undefined,
    component: () => import("/_app/pages/drinks.vue").then(m => m.default || m)
  },
  {
    name: foodVO6JJm1Z0EMeta?.name ?? "food",
    path: foodVO6JJm1Z0EMeta?.path ?? "/food",
    meta: foodVO6JJm1Z0EMeta || {},
    alias: foodVO6JJm1Z0EMeta?.alias || [],
    redirect: foodVO6JJm1Z0EMeta?.redirect || undefined,
    component: () => import("/_app/pages/food.vue").then(m => m.default || m)
  },
  {
    name: galleryGippOHOjWmMeta?.name ?? "gallery",
    path: galleryGippOHOjWmMeta?.path ?? "/gallery",
    meta: galleryGippOHOjWmMeta || {},
    alias: galleryGippOHOjWmMeta?.alias || [],
    redirect: galleryGippOHOjWmMeta?.redirect || undefined,
    component: () => import("/_app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: index7414TfuuzVMeta?.name ?? "index",
    path: index7414TfuuzVMeta?.path ?? "/",
    meta: index7414TfuuzVMeta || {},
    alias: index7414TfuuzVMeta?.alias || [],
    redirect: index7414TfuuzVMeta?.redirect || undefined,
    component: () => import("/_app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: our_45coffeegeOWnf69nAMeta?.name ?? "our-coffee",
    path: our_45coffeegeOWnf69nAMeta?.path ?? "/our-coffee",
    meta: our_45coffeegeOWnf69nAMeta || {},
    alias: our_45coffeegeOWnf69nAMeta?.alias || [],
    redirect: our_45coffeegeOWnf69nAMeta?.redirect || undefined,
    component: () => import("/_app/pages/our-coffee.vue").then(m => m.default || m)
  }
]